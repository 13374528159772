<template>
  <v-container fluid class="relative">
    <template v-if="isWorking">
      <div class="mt-n3 d-flex row scroll-bar">
        <v-progress-linear indeterminate color="blue darken-2" />
      </div>
    </template>

    <v-row align="center" justify="center">
      <v-col cols="7">
        <v-banner
          class="rounded-lg"
          justify="center"
          elevation='2'
          icon='mdi-help-box'
          color="white">
          <p class="ml-3 mt-4 subheading">Se tiver alguma dúvida, visite a nossa <router-link :to="{name:'Helpview'}"> página de ajuda.</router-link> </p>
        </v-banner>
      </v-col>
    </v-row>

    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row align="center" justify="center">
        <v-col cols="7">
          <v-container align-center class="mt-5 white rounded-lg">
            <v-row class="ml-3">
              <p class="headline font-weight-light">Solicitação</p>
            </v-row>
            <v-row>
              <v-divider></v-divider>
            </v-row>

            <p class="ml-3 mt-4 subheading">
              Por gentileza, faça o upload do ofício
            </p>
            <v-row no-gutters class="mb-5">
              <v-col cols="4"></v-col>
              <v-col cols="4">
                <v-file-input
                  :rules="oficioRules"
                  accept=".pdf"
                  label="Adicionar ofício"
                  color="success"
                  v-model="oficio"
                ></v-file-input>
              </v-col>
              
            </v-row>
            <!-- Divisor -->
            <v-row>
              <v-divider></v-divider>
            </v-row>
            <div>
              <v-row>
                <v-col cols="11">
                <p class="ml-3 mt-4 subheading">Empresa</p>
                </v-col>
                <v-col cols="1" >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div align="center" justify="center" class="ml-3 mt-4 grey lighten-2 text-no-wrap rounded-pill">
                          <p
                          class="grey--text"
                          v-bind="attrs"
                          v-on="on"
                        >?</p>
                      </div>
                    </template>
                    <span>Insira o nome e url (no site do ReclameAqui) da empresa que gostaria de incluir na pesquisa <br>EX: Uma Empresa | https://www.reclameaqui.com.br/empresa/uma_empresa/</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <span v-for="(row, index) in rows" :key="index">
                <v-row align="center" justify="center">
                  <v-col cols="11">
                    <v-text-field
                      v-model="row.name"
                      label="Qual o nome da empresa pesquisada?"
                      outlined
                      color="light-green lighten-1"
                      :rules="companyNameRules"
                    ></v-text-field>
                  </v-col>                  
                </v-row>
                <v-row align="center" justify="center">
                  <v-col cols="11">
                    <v-text-field
                      v-model="row.url"
                      label="Qual a URL no ReclameAQUI da empresa a ser pesquisada?"
                      outlined
                      color="light-green lighten-1"
                      :rules="companyUrlRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </span>
              <v-row align="center" justify="center">
                <v-btn
                  @click="addCompany"
                  color="green accent-1"
                  class="rounded-lg mb-5"
                  small
                  depressed
                >
                  Adicionar outra empresa
                </v-btn>
                <v-col cols="1"></v-col>
                <v-btn
                  @click="removeCompany"
                  color="red accent-1"
                  class="rounded-lg mb-5"
                  small
                  depressed
                  v-if="rows.length > 1"
                >
                  Remover uma empresa
                </v-btn>
              </v-row>
            </div>
            <v-row>
              <v-divider></v-divider>
            </v-row>
            <v-row>
              <v-col cols="11">
                <p class="ml-3 mt-4 subheading">Sobre o Relatório</p>
              </v-col>
              <v-col cols="1" >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div align="center" justify="center" class="ml-3 mt-4 grey lighten-2 text-no-wrap rounded-pill">
                        <p
                        class="grey--text"
                        v-bind="attrs"
                        v-on="on"
                      >?</p>
                    </div>
                  </template>
                  <span>Descreva o problema que gostaria de resolver com o<br>seu pedido de relatório (Não utilizar vírgulas).<br>Escreva palavras-chave e utilize os operadores para<br>criar um conjunto de palavras que utilizaremos<br>para procurar no banco<br>EX:<br>-Gostaria de receber informação sobre problema X<br>-fraude E atendimento OU atraso</span>
                </v-tooltip>
              </v-col>
            </v-row>
            
            <!-- Divisor -->
            <span ref='keywordsArray' v-for="(row2, index2) in rows2" :key="index2">
              <v-row align="center" justify="center">
                <v-col cols="11">
                  <v-text-field
                    label="Qual problema gostaria de receber informações sobre?"
                    outlined
                    color="light-green lighten-1"
                    v-model="row2.reportNeeds"
                    :rules="reportNeedsRules"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="ml-3" align="left" justify="left">
                Operadores:
                <v-btn
                  @click="addOperator(index2, 'E')"
                  color="grey accent-1"
                  class="ml-2 rounded-lg"
                  small
                  depressed>
                  E
                </v-btn>
                <v-btn
                  @click="addOperator(index2, 'OU')"
                  color="grey accent-1"
                  class="ml-2 rounded-lg"
                  small
                  depressed>
                  OU
                </v-btn>
                <v-btn
                  @click="addOperator(index2, '(')"
                  color="grey accent-1"
                  class="ml-2 rounded-lg"
                  small
                  depressed>
                  (
                </v-btn>
                <v-btn
                  @click="addOperator(index2, ')')"
                  color="grey accent-1"
                  class="ml-2 rounded-lg"
                  small
                  depressed>
                  )
                </v-btn>
              </v-row>
              <v-row align="center" justify="center">
                <v-col cols="11">
                  <v-textarea
                    :ref="`keywordsField_${index2}`"
                    label="Quais as palavras-chaves que gostaria de pesquisar na base do ReclameAQUI sobre esse problema específico? MAX 300 caracteres"
                    outlined
                    auto-grow
                    color="light-green lighten-1"
                    v-model="row2.keyWords"
                    :rules="keyWordsRules"
                  ></v-textarea>
                </v-col>
              </v-row>
            </span>
            <v-row align="center" justify="center">
              <v-btn
                @click="addProblem"
                color="green accent-1"
                class="rounded-lg mb-5"
                small
                depressed
              >
                Adicionar outro problema
              </v-btn>
              <v-col cols="1"></v-col>
              <v-btn
                @click="removeProblem"
                color="red accent-1"
                class="rounded-lg mb-5"
                small
                depressed
                v-if="rows2.length > 1"
              >
                Remover um problema
              </v-btn>
            </v-row>

            <v-row>
              <v-divider></v-divider>
            </v-row>
            <v-row>
              <v-col cols="11">
                  <p class="ml-3 mt-4 subheading">
                    Estado a ser pesquisado
                  </p>
              </v-col>
              <v-col cols="1" >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div align="center" justify="center" class="ml-3 mt-4 grey lighten-2 text-no-wrap rounded-pill">
                          <p
                          class="grey--text"
                          v-bind="attrs"
                          v-on="on"
                        >?</p>
                      </div>
                    </template>
                    <span>Iremos pesquisar reclamações somente no estado que selecionar (ou todos os estados)</span>
                  </v-tooltip>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="11">
                <v-select
                  v-model="userStateBox"
                  :items="userStateBoxItem"
                  :rules="userStateSelectRule"
                  small-chips
                  label="Selecione qual estado gostaria que a busca focasse"
                  multiple
                >
                  <template v-slot:prepend-item>
                    <v-list-item
                      ripple
                      @mousedown.prevent
                      @click="toggle"
                    >
                      <v-list-item-action>
                        <v-icon :color="userStateBox.length > 0 ? 'indigo darken-4' : ''">
                          {{ icon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          Todos
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="11">
                <p class="ml-3 mt-4 subheading" v-if="isRequired == true">
                  Cidade a ser pesquisada
                </p>
              </v-col>
            <v-col cols="1" >
                  <v-tooltip bottom v-if="isRequired == true">
                    <template v-slot:activator="{ on, attrs }">
                      <div align="center" justify="center" class="ml-3 mt-4 grey lighten-2 text-no-wrap rounded-pill">
                          <p
                          class="grey--text"
                          v-bind="attrs"
                          v-on="on"
                        >?</p>
                      </div>
                    </template>
                    <span>Iremos pesquisar reclamações somente nas cidades que selecionar (ou todas as cidades)</span>
                  </v-tooltip>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="11">
                <v-combobox
                  :rules="isRequired ? userStateCityBoxRule : []"
                  :required="isRequired"
                  v-if="isRequired == true"
                  ref="userStateCityBox"
                  :items="userStateCityBoxItems"
                  label="Selecione qual cidade gostaria que a busca focasse"
                  color="success"
                  v-model="userStateCityBox"
                  multiple
                  clearable
                  chips
                ></v-combobox>
              </v-col>
            </v-row>

            <!-- Divisor -->
            <v-row>
              <v-divider></v-divider>
            </v-row>

            <p class="ml-3 mt-4 subheading">Qual o período a ser pesquisado?</p>
            <v-row align="center" justify="center">
              <v-col cols="11">
                <v-radio-group v-model="searchTime" :rules="radioRules">
                  <v-row>
                    <v-radio label="6 Meses" color="success" value="6 Meses">
                    </v-radio>
                  </v-row>
                  <v-row>
                    <v-radio
                      label="12 Meses"
                      class="mt-5"
                      color="success"
                      value="12 Meses"
                    >
                    </v-radio>
                  </v-row>
                  <v-row>
                    <v-radio
                      label="2 Anos"
                      class="mt-5"
                      color="success"
                      value="2 Anos"
                    >
                    </v-radio>
                  </v-row>
                  <v-row>
                    <v-radio
                      label="3 Anos"
                      class="mt-5"
                      color="success"
                      value="3 Anos"
                    >
                    </v-radio>
                  </v-row>
                  <v-row>
                    <v-radio
                      id="searchTimeOther"
                      label="Outro:"
                      color="success"
                      value="outros"
                    >
                    </v-radio>
                    <v-col cols="5">
                      <v-text-field
                        ref="dateRangeText"
                        :value="dateRangeText"
                        label="Date range"
                        class="ml-3"
                        prepend-icon="mdi-calendar"
                        readonly
                        @click="checkradioOther('searchTime')"
                        color="light-green lighten-1"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="1"></v-col>
                    <v-col cols="6">
                      <v-date-picker
                        v-model="dates"
                        range
                        no-title
                        v-if="
                          searchTime == 'outros' ||
                          (searchTime || ``).includes('undefined')
                        "
                        color="green lighten-1"
                      ></v-date-picker>
                    </v-col>
                  </v-row>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <vue-recaptcha
                ref="recaptcha"
                @verify="onCaptchaVerified"
                @expired="onCaptchaExpired"
                sitekey="6LcuZeYZAAAAAGxWajGTy_zty-jQTuQz7hSbi8mH"
                :load-recaptcha-script="true"
              >
              </vue-recaptcha>
            </v-row>
            <v-row class="ml-5">
              <v-checkbox v-model="checkboxAgree">
                <template v-slot:label>
                  <div>
                    Li e concordo com os
                    <a href="LGPD/termos.pdf" target="_blank" @click.stop>
                      termos de uso em relação à LGPD
                    </a>
                  </div>
                </template>
              </v-checkbox>
            </v-row>
          </v-container>
          <v-row class="mt-5" align="center" justify="center">
            <v-btn
              color="success"
              :disabled="!valid || isWorking"
              @click="validate"
              :loading="isWorking"
            >
              Enviar
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import VueRecaptcha from "vue-recaptcha";

export default {
  name: 'Form',
  components: {
    "vue-recaptcha": VueRecaptcha
  },
  data: () => ({
    isWorking: false,
    isRequired: false,
    rows: [
      {
        name: '',
        url: '',
      },
    ],
    rows2: [
      {
        reportNeeds: '',
        keyWords: '',
      },
    ],
    recaptchaToken: '',
    companyNameRules: [(v) => !!v || 'Nome é necessário'],
    companyUrlRules: [
      (v) => !!v || 'URL é necessário',
      (v) =>
        /reclameaqui.com.br\/empresa\//.test(v) ||
        'URL deve ser uma URL do site do ReclameAqui válida',
    ],
    valid: true,
    additional_info: '',
    nameField: '',
    nameFieldRules: [
      (v) => !!v || 'Nome é necessário',
      (v) => v?.length >= 10 || 'Nome não pode ser menor que 10 caracteres',
    ],
    emailField: '',
    emailFieldRules: [
      (v) => !!v || 'Email é necessário',
      (v) => /.+@.+\..+/.test(v) || 'Email deve ser um email válido',
    ],
    stateBox: '',
    stateBoxRules: [(v) => !!v || 'Selecione um estado'],
    workTime: '',
    reportNeeds: '',
    reportNeedsRules: [
      (v) => !!v || 'É necessário especificar o que você precisa no relatório',
    ],
    keyWords: '',
    keyWordsRules: [
      (v) => !!v || 'É necessário especificar as palavras chaves',
      (v) => (v && v.length <= 300) || "Este campo deve ter menos de 300 caracteres",
    ],
    radioRules: [(v) => !!v || 'É necessário selecionar uma opção!'],
    organizationOtherText: '',
    jobOtherText: '',
    stateOther: '',
    searchTimeOther: '',
    estados: '',
    job: '',
    organization: '',
    searchTime: '',
    dates: [],
    spreadsheet: [],
    oficio: [],
    oficioRules: [
      (v) =>
        !!v ||
        (v?.size < 5000000 && /(\.pdf)$/.exec(v?.name)) ||
        'Você não enviou um arquivo válido, você deve enviar um arquivo .pdf que seja menor de 5 MB',
    ],
    userOffice:'',
    userAccountState:'',
    userStateCityBox: [],
    userStateCityBoxItems: [],
    userStateCityBoxRule: [(v) => !!v || 'Selecione uma opção'],
    userStateBox: [],
    userStateBoxItem: [],
    userStateSelectRule: [(v) => !!v || 'Selecione uma opção'],
    timeToSearch: '',
    estadosSelectRule: [(v) => !!v || 'Selecione um estado'],
    checkboxAgree: '',
    checkboxAgreeRules: [(v) => !!v || 'Você tem que aceitar os termos'],
    fileUploadRules: [(v) => !!v || 'Você tem que aceitar os termos'],
  }),
  watch: {
    job: function (val) {
      if (val === 'outros') {
        this.$refs.jobOtherText.focus();
      }
    },
    userStateBox: function (val) {
      this.userStateCityBox = ''
      this.userStateCityBoxItems = ["Todos"]

      if (this.userStateBox.length > 1 || this.userStateBox.length < 1){
        this.isRequired = false
      }
      else{
        fetch(`https://storage.googleapis.com/ra-dataanalytics-assets/json/states/state_${val[0]}.json`)
        .then(res => res.json())
        .then((out) => {
          for (let i = 0; i < out.length; i++) {
            this.userStateCityBoxItems.push(out[i].nome)
          }})
        this.isRequired = true
      }
    },
    organization: function (val) {
      if (val === 'outros') {
        this.$refs.organizationOtherText.focus();
      }
    },
    searchTime: function (val) {
      if (val === '6 Meses') {
        this.timeToSearch = `${moment()
          .subtract(7, 'months')
          .format('YYYY-MM-01')}~${moment()
          .subtract(1, 'months')
          .endOf('month')
          .format('YYYY-MM-DD')}`;
      } else if (val === '12 Meses') {
        this.timeToSearch = `${moment()
          .subtract(13, 'months')
          .format('YYYY-MM-01')}~${moment()
          .subtract(1, 'months')
          .endOf('month')
          .format('YYYY-MM-DD')}`;
      } else if (val === '2 Anos') {
        this.timeToSearch = `${moment()
          .subtract(25, 'months')
          .format('YYYY-MM-01')}~${moment()
          .subtract(1, 'months')
          .endOf('month')
          .format('YYYY-MM-DD')}`;
      } else if (val === '3 Anos') {
        this.timeToSearch = `${moment()
          .subtract(37, 'months')
          .format('YYYY-MM-01')}~${moment()
          .subtract(1, 'months')
          .endOf('month')
          .format('YYYY-MM-DD')}`;
      }else if (val === 'outros') {
        this.$refs.dateRangeText.focus();
      }
    },
    dates: function (val) {
      this.timeToSearch = `${val[0]}~${val[1]}`;
    },
  },
  methods: {
    toggle () {
        this.$nextTick(() => {
          if (this.allStates) {
            this.userStateBox = []
          } else {
            this.userStateBox = this.userStateBoxItem.slice()
          }
        })
      },
    validateExpression(expression){
      try {
        eval(expression);
        return true
      }
      catch (e) {
        console.log(e)
        return false
      }
    },
    sentenceToMath(sentence){
      sentence = sentence.toLowerCase().replace(new RegExp(/([ \t]+)(ou|e)([ \t]+)/, 'g'), " + ")
      return sentence.normalize('NFD').replace(new RegExp(/SELECT|UPDATE|CREATE|DELETE|WHERE|[*,.|/\\\n]|[\u0300-\u036f]/, 'g'), "").replace(new RegExp(/OU|E|&/, 'g'), " + ").replace(new RegExp(/([ \t]*)[!]([ \t]*)/, 'g'), "-").replace(new RegExp(/([ \t]*)[a-zA-Z]([ \t]*)/, 'g'), "1")
    },
    validateKeywords(){
      var failureFinder = []
      for(var i=0;i<this.rows2.length;i++){
        const newStr = this.sentenceToMath(this.rows2[i].keyWords)
        if(this.validateExpression(newStr) != true){
          failureFinder.push(i+1)
        }
      }
      return failureFinder
    },
    addOperator(index, operador){
      this.rows2[index].keyWords += ' '+operador+' ';
      this.$refs[`keywordsField_${index}`][0].focus();
    },
    addCompany() {
      this.rows.push({
        name: ' ',
        url: ' ',
      });
    },
    removeCompany() {
      this.rows.pop();
    },
    addProblem() {
      this.rows2.push({
        reportNeeds: ' ',
        keyWords: ' ',
      });
    },
    removeProblem() {
      this.rows2.pop();
    },
    checkradioOther(id) {
      this[id] = 'outros';
    },
    onCaptchaVerified(recaptchaToken) {
      this.recaptchaToken = recaptchaToken;
    },
    onCaptchaExpired: function () {
      this.$refs.recaptcha.reset();
    },
    validate() {
      this.$refs.form.validate();

      this.$nextTick(this.sendData);
    },
    async sendData() {
      this.getData()
      const failedKeywords = this.validateKeywords()
      if(failedKeywords.length > 0){
        this.$swal.fire({
          icon: 'error',
          title: `"As palavras-chave estão com formato incorreto, por favor verifique os campos ${failedKeywords.join(", ")}"`,
        });
        return;
      }
      if (!this.valid){
        this.$swal.fire({
          icon: 'error',
          title: `Por favor, verifique os campos preenchidos.`,
        });
        return;
      }
      this.isWorking = true;

      const names = [];
      const urls = [];
      this.rows.map((company) => {
        names.push(company.name.replaceAll(',',''));
        urls.push(company.url.replaceAll(',',''));
      });

      let keyWords = '';
      const reportNeeds = [];
      this.rows2.forEach((problem, index, array) => {
        if (index === array.length - 1){
          keyWords += problem.keyWords.replaceAll(',',' OU').replaceAll('||', ' E ');
        }else {
          keyWords += problem.keyWords.replaceAll(',',' OU').replaceAll('||', ' E ').concat(' & ');
        }
        reportNeeds.push(problem.reportNeeds.replaceAll(',',''));
      });

      const formdata = new FormData();
      formdata.append('pdf', this.oficio);
      formdata.append('sheet', this.spreadsheet);

      const options = {
        method: 'POST',
        url: process.env.VUE_APP_SERVICE_NAME + 'api/files/',
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: 'Token ' + localStorage.getItem('token'),
        },
        data: formdata,
      };

      const fileResponse = await axios
        .request(options)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          this.isWorking = false;
          this.$swal.fire({
            icon: 'error',
            title: `Houve algum erro ao enviar o formulário, espere um pouco e tente de novo`,
          });
          console.error(error);
        });

      const reportData = {
        name: localStorage.getItem('name'),
        email: localStorage.getItem('email'),
        state: this.userAccountState,
        office: this.userOffice,
        requester: this.organization,
        files_id: fileResponse.data.id,
        names: names.toString(),
        links: urls.toString(),
        report: reportNeeds.toString(),
        keywords: keyWords,
        user_state: (this.userStateBox.length >= this.userStateBoxItem.length ? "Todos" : this.userStateBox.join(',')),
        city: (this.userStateCityBox.length >= 1 ? this.userStateCityBox.join(',') : "Todos"),
        time: this.timeToSearch,
        deadline: '2 Semanas',
        additional_info: this.additional_info,
        recaptcha: this.recaptchaToken
      };

      await axios
        .post(
          process.env.VUE_APP_SERVICE_NAME + 'api/relatorios/',
          reportData,
          {
            headers: {
              Authorization: 'Token ' + localStorage.getItem('token'),
            },
          }
        )
        .then((response) => {
          this.$swal.fire({
            icon: 'success',
            title: `O ID da sua solicitação é:\n ${String(response.data.id)}`,
          });
          this.rows = [{
            name: '',
            url: ''
          }];
          this.rows2 = [{
            reportNeed: '',
            keyWord: ''
          }];
          this.$refs.form.reset();
          window.scrollTo(0,0);
        })
        .catch((e) => {
          this.isWorking = false;
          this.$swal.fire({
            icon: 'error',
            title: `Erro ao enviar dados`,
            text: e.response.data.detail ? e.response.data.detail : `Favor verificar os campos.`
          });
          console.log(e);
        })
        .finally(() => {
          this.isWorking = false;
          this.$refs.recaptcha.reset();
        });
    },
    userToken(){
      const userToken = localStorage.getItem('token');
      return userToken;
    },
    async getData(){
        const options = {
              method: 'GET',
              url: process.env.VUE_APP_SERVICE_NAME + "api/accounts/users/me/",
              headers: {authorization: "Token " + this.userToken()}
            };
        await axios.request(options).then((response) => {
            this.organization = response.data.aggency
            this.userOffice = response.data.office
            this.userAccountState = response.data.state
        }).catch(function (error) {
              console.error(error);
            });
    },
  },
  computed: {
    dateRangeText() {
      return this.dates.join(' ~ ');
    },
      allStates () {
        return this.userStateBox.length === this.userStateBoxItem.length
      },
      someStates () {
        return this.userStateBox.length > 0 && !this.allStates
      },
      icon () {
        if (this.allStates) return 'mdi-close-box'
        if (this.someStates) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
  },
  created() {
    fetch('https://storage.googleapis.com/ra-dataanalytics-assets/json/states.json')
    .then(res => res.json())
    .then((out) => {
        for (let i = 0; i < out.length; i++) {
            this.userStateBoxItem.push({value:out[i].sigla, text:out[i].nome})
          }})
    
    const options = {
      method: 'GET',
      url: process.env.VUE_APP_SERVICE_NAME + 'api/accounts/users/me/',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Token ' + localStorage.getItem('token'),
      }
    };

    axios.request(options)
      .then((response) => {
        this.emailField = response.data.email;
        this.nameField = response.data.first_name + ' ' + response.data.last_name;
        return response;
      }).catch((error) => {
      console.error(error);
    });
  },
};
</script>

<style>
.scroll-bar {
  position: absolute !important;
  top: 150px;
  left: 0;
  z-index: 150;
}
</style>
