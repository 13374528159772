<template>
  <v-container fluid>
    <v-row align="center" justify="center" v-if="reports == null || reports.length <= 1">
      <p class="class=text-right headline font-weight-regular title font-weight-light">Não há nenhum relatório cadastrado.</p>
    </v-row>
    <v-row align="center" justify="center" v-for="report in reports[page]" :key="report">
      <v-col cols="8">
        <v-container align-center class="white rounded-lg">
          <div class="mx-3">
            <v-row class="mx-1 mt-3">
              <p class="headline font-weight-light">{{ report.report }}</p>
              <v-spacer></v-spacer>
              <p class="text-right headline font-weight-bold">
                ID: {{ report.id }}
              </p>
            </v-row>

            <v-row>
              <v-divider></v-divider>
            </v-row>

            <v-row class="mx-3 mt-3">
              <p class="title font-weight-light">Solicitante</p>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="12">
                <v-text-field
                  outlined
                  disabled
                  label="Órgão"
                  v-model="report.requester"
                ></v-text-field>
              </v-col>
<!--              <v-col cols="6">-->
<!--                <v-autocomplete-->
<!--                  append-icon=""-->
<!--                  ref="estadosBox"-->
<!--                  outlined-->
<!--                  disabled-->
<!--                  label="Estado"-->
<!--                  :items="estadosBoxItems"-->
<!--                  v-model="report.state"-->
<!--                ></v-autocomplete>-->
<!--              </v-col>-->
            </v-row>

            <v-row>
              <v-divider></v-divider>
            </v-row>

            <v-row class="mx-3 mt-3">
              <p class="title font-weight-light">Período</p>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="6">
                <v-text-field
                  label="Data Inicial"
                  outlined
                  disabled
                  color="light-green lighten-1"
                  v-model="report.time[0]"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Data Final"
                  outlined
                  disabled
                  v-model="report.time[1]"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>

          <v-row>
            <v-divider></v-divider>
          </v-row>

          <!--EMPRESA-->
          <v-row class="mx-3 mt-3">
            <p class="title font-weight-light">Empresa</p>
          </v-row>
          <v-row class="mx-1" align="center" justify="center">
            <v-col cols="12">
              <v-flex xs12>
                <v-combobox multiple
                            v-model="report.links"
                            label="Links"
                            append-icon
                            outlined
                            disabled
                            chips
                            color="green"
                            deletable-chips
                            class="tag-input">
                </v-combobox>
              </v-flex>
            </v-col>
          </v-row>

          <v-row>
            <v-divider></v-divider>
          </v-row>

          <!--palavra-chave-->
          <v-row class="mx-3 mt-3">
            <p class="title font-weight-light">Palavras-chave</p>
          </v-row>
          <v-row class="mx-1" align="center" justify="center">
            <v-col cols="12" v-for="(_, idx) in report.problemsKeywords" :key="idx">
              <v-flex xs12>
                <v-textarea v-model="report.problemsKeywords[idx]"
                  :label="`Palavras-chave ${report.problems[idx]}`"
                  outlined
                  auto-grow
                  disabled
                  color="green">
                </v-textarea>
              </v-flex>
            </v-col>
          </v-row>

          <v-row>
            <v-divider></v-divider>
          </v-row>

          <v-row class="mx-3 mt-3">
            <p class="title font-weight-light">Estado das reclamações</p>
          </v-row>
          <v-row class="mx-1" align="center" justify="center">
            <v-col cols="12">
              <v-text-field
                v-if="report.user_state === 'Todos'"
                label="Estado"
                outlined
                disabled
                v-model="report.user_state"
              ></v-text-field>
              <v-autocomplete
                v-if="report.user_state !== 'Todos'"
                append-icon=""
                ref="estadosBox"
                outlined
                disabled
                label="Estado"
                :items="estadosBoxItems"
                v-model="report.user_state"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-divider></v-divider>
          </v-row>

          <v-row class="mx-3 mt-3">
            <p class="title font-weight-light">Observação</p>
          </v-row>
          <v-row class="mx-1" align="center" justify="center">
            <v-col cols="12">
              <v-text-field
                label="Observação"
                outlined
                disabled
                v-model="report.additional_info"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-divider></v-divider>
          </v-row>

          <v-row class="mx-3 mt-3">
            <p class="title font-weight-light">Status</p>
          </v-row>
          <v-row class="mx-1" align="center" justify="center">
            <v-col cols="6">
              <span class="mb-2">Time Jurídico:</span>
              <span v-if="report.jurid_aprov === '0'" class="white--text ml-1 pa-1 rounded new badge orange">Aguardando</span>
              <span v-else-if="report.jurid_aprov === '1'" class="white--text ml-1 pa-1 rounded new badge green">Aprovado</span>
              <span v-else class="white--text ml-1 pa-1 rounded new badge red">Reprovado</span>
              <v-text-field
                class="mt-2"
                outlined
                disabled
                label="Motivo"
                :value="report.jurid_aprov !== '1' && report.jurid_aprov !== '0' ? report.jurid_aprov : ''"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-divider></v-divider>
          </v-row>

          <v-row class="mx-3 mt-3">
            <p class="title font-weight-light">Adicionais</p>
          </v-row>
          <v-row class="mx-1" align="center" justify="center">
            <v-col cols="6">
              <v-text-field
                label="Data de Cadastro"
                outlined
                disabled
                v-model="report.created_date"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Executado"
                outlined
                disabled
                :value="report.executed ? report.modified_date : 'Não executado'"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-pagination
      v-model="page"
      :length="Math.ceil(reportsData.length/perPage)"
    ></v-pagination>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from 'moment';

export default {
  data: () => ({
    reports: [[]],
    reportsData: [],
    page: 1,
    perPage: 5,
    estadosBoxItems: [{ value: null, text: 'Selecione um estado' }]
  }),
  methods: {
    preparefields(report) {
      report.time = report.time.split('~')
      const dateFormat = 'DD/MM/yyyy';
      report.time[0] = moment(report.time[0]).format(dateFormat);
      report.time[1] = moment(report.time[1]).format(dateFormat);

      const dateFormatHour = 'DD/MM/yyyy HH:MM';
      report.created_date = moment(report.created_date).format(dateFormatHour);

      if (report.modified_date) report.modified_date = moment(report.modified_date).format(dateFormatHour);

      report.links = report.links.split(',')
      report.problems = report.report.split(',')

      report.keywords = report.keywords.replaceAll(',',' OU ');
      report.keywords = report.keywords.replaceAll('||',' E ');
      report.problemsKeywords = []
      report.keywords.split('&').forEach((keywordsItem) => {
        report.problemsKeywords.push([keywordsItem])
      })

      return report
    }
  },
  created(){
    fetch(`${process.env.VUE_APP_STATES}`)
    .then(res => res.json())
    .then((out) => {
        for (let i = 0; i < out.length; i++) {
            this.estadosBoxItems.push({value:out[i].sigla, text:out[i].nome})
          }})
  },
  mounted() {
    axios
      .get(process.env.VUE_APP_SERVICE_NAME + "api/accounts/users/me/", {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(response => {
        axios
          .get(process.env.VUE_APP_SERVICE_NAME + "api/relatorios/?email="+response.data.email, {
            headers: {
              Authorization: "Token " + localStorage.getItem("token")
            }
          })
          .then(response => {
            const array = response.data['results'];
            array.sort(function (a,b) {
              return b.id - a.id;
            })

            for (var x in array) {
              var report = array[x];
              report = this.preparefields(report);
              this.reportsData.push(report);
            }
            
            for (var i = 0; i < this.reportsData.length; i+=this.perPage){
            this.reports.push(this.reportsData.slice(i,i+this.perPage));
            }
          })
          .catch(e => {
            console.log(e);
          })
      })
      .catch(e => {
        console.log(e);
      })
  }
};
</script>
