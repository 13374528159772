<template>
  <v-container fluid class="relative">
    <template v-if="isWorking">
      <div class="mt-n3 d-flex row scroll-bar">
        <v-progress-linear indeterminate color="blue darken-2" />
      </div>
    </template>

    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row align="center" justify="center">
        <v-col cols="7">
          <v-container align-center class="mt-5 white rounded-lg">
            <p class="ml-3 mt-4 subheading">Cadastrar nova senha</p>
            <v-row align="center" justify="center">
              <v-col cols="11">
                <v-text-field
                  outlined
                  color="light-green lighten-1"
                  :append-icon="showPass1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="passwordRules"
                  :type="showPass1 ? 'text' : 'password'"
                  v-model="passwords.password"
                  name="user-password"
                  placeholder="Informe a senha"
                  label="Senha"
                  @click:append="showPass1 = !showPass1"
                  data-test="passwdField"
                  autocomplete="on"
                />
              </v-col>
            </v-row>

            <v-row align="center" justify="center">
              <v-col cols="11">
                <v-text-field
                  outlined
                  color="light-green lighten-1"
                  :append-icon="showPass2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="passwordsMatch"
                  :type="showPass2 ? 'text' : 'password'"
                  v-model="passwords.password2"
                  name="user-password2"
                  label="Confirme a Senha"
                  placeholder="Informe a senha"
                  autocomplete="off"
                  @click:append="showPass2 = !showPass2"
                />
              </v-col>
            </v-row>
          </v-container>
          <v-row class="mt-5" align="center" justify="center">
            <v-btn
              color="success"
              :disabled="!valid || isWorking"
              @click="validate"
              :loading="isWorking"
            >
              Criar
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import axios from 'axios';

const passwords = {
  password: "",
  password2: ""
};

export default {
  name: 'Form',
  components: {

  },
  data: () => ({
    showPass1: false,
    showPass2: false,
    passwords,
    passwordRules: [ (v) => !!v || 'Senha deve ser informada.', (v) => (v && v.length >= 6) || "Min 6 caracteres"],
    newPasswordPost: {
      password: '',
      token: ''
    },
    isWorking: false,
    valid: true,
    recaptchaToken: '',
    passwordsMatch: [() => passwords.password === passwords.password2 || "Senhas não conferem"]
  }),
  methods: {
    onCaptchaVerified(recaptchaToken) {
      this.recaptchaToken = recaptchaToken;
    },
    onCaptchaExpired: function () {
      this.$refs.recaptcha.reset();
    },
    validate() {
      this.$refs.form.validate();
      this.$nextTick(this.sendData);
    },
    async sendData() {
      if (!this.valid){
        this.$swal.fire({
          icon: 'error',
          title: `Por favor, verifique os campos preenchidos.`,
        });
        return;
      }
      this.isWorking = true;

      this.newPasswordPost = {
        password: passwords.password,
        code: this.$route.query.token
      }

      await axios
        .post(process.env.VUE_APP_SERVICE_NAME + 'api/accounts/password/reset/verified/',this.newPasswordPost)
        .then(() => {
          this.$swal.fire({
            icon: 'success',
            title: `Nova senha cadastrada.`
          });
          window.scrollTo(0,0);
          this.$refs.form.reset();
          this.$router.push({name: 'Loginview'});
        })
        .catch((e) => {
          this.isWorking = false;
          this.$swal.fire({
            icon: 'error',
            title: `Erro ao enviar dados`,
            text: e.response.data.detail ? e.response.data.detail : `Favor verificar os campos.`
          });
          console.log(e);
        })
        .finally(() => {
          this.isWorking = false;
          this.$refs.recaptcha.reset();
        });
    },
  }
};
</script>

<style>
.scroll-bar {
  position: absolute !important;
  top: 150px;
  left: 0;
  z-index: 150;
}
</style>
