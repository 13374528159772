<template>
  <v-container fluid>
    <v-row align="center" justify="center">
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  async created() {
    const urlToken = this.$route.query.token;
    const result = await axios.get(`${process.env.VUE_APP_SERVICE_NAME}api/accounts/signup/verify/?code=${urlToken}`,
      {"content-type": "application/x-www-form-urlencoded"});

    if (result.status === 200){
      this.$swal.fire({
        icon: 'success',
        title: `Usuário autorizado`
      });
      this.$router.push({name: 'Loginview'});
    }else{
      this.$swal.fire({
        icon: 'error',
        title: `Erro na validação`,
        text: `Verificar com suporte da aplicação`
      });
    }
  }
}
</script>

<style>
.scroll-bar {
  position: absolute !important;
  top: 150px;
  left: 0;
  z-index: 150;
}
</style>
