<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-row class="mt-5" align="center" justify="center">
            <v-btn
              color="success"
              @click="downloadFile"
            >
              Download
            </v-btn>
          </v-row>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";

export default {
  methods:{
    async downloadFile(){
        const urlToken = this.$route.query.token;
        await axios.get(`${process.env.VUE_APP_SERVICE_NAME}api/report_token/?token=${urlToken}`,{responseType: 'blob',headers: {Authorization: 'Token ' + localStorage.getItem('token') }})
            .then(response => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');
              
                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'file.pdf');
                document.body.appendChild(fileLink);
                fileLink.click();
                this.$swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'O download do arquivo vai começar em instantes',
                    showConfirmButton: false,
                    timer: 1500
                })
                this.$router.push({name: 'Formview'});
              }).catch(this.$swal.fire({
                      icon: 'error',
                      title: `Erro no download, verifique se esta logado com o usuário correto e de que o link não esta expirado`,
                      text: `Tente logar novamente com seu usuário ou contate o suporte da aplicação`,
                      confirmButtonText: 'Ok',
                  }
                ).then((result) => {
                    if (result.isConfirmed) {
                      this.$router.push({name: 'Formview'});
                    }
                  }
                )
              )
    }
  }
}
</script>

<style>
.scroll-bar {
  position: absolute !important;
  top: 150px;
  left: 0;
  z-index: 150;
}
</style>