<template>
  <v-container fluid class="relative">
    <template v-if="isWorking">
      <div class="mt-n3 d-flex row scroll-bar">
        <v-progress-linear indeterminate color="blue darken-2" />
      </div>
    </template>

    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row align="center" justify="center">
        <v-col cols="7">
          <v-container align-center class="mt-5 white rounded-lg">
            <p class="ml-3 mt-4 subheading">Novo usuário</p>
            <v-row align="center" justify="center">
              <v-col cols="11">
                <v-text-field
                  v-model="userForm.name"
                  label="Nome"
                  placeholder="Nome completo"
                  outlined
                  color="light-green lighten-1"
                  :rules="nameFieldRules"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row align="center" justify="center">
              <v-col cols="11">
                <v-text-field
                  v-model="userForm.email"
                  label="Email"
                  placeholder="Email"
                  outlined
                  color="light-green lighten-1"
                  :rules="emailFieldRules"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row align="center" justify="center">
              <v-col cols="11">
                <v-text-field
                  v-model="userForm.agency"
                  label="Orgão"
                  placeholder="Nome do orgão completo"
                  outlined
                  color="light-green lighten-1"
                  :rules="agencyFieldRules"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row align="center" justify="center">
              <v-col cols="11">
                <v-select
                  ref="userstateBox"
                  :items="userstateBoxItems"
                  label="Selecione o seu estado correspondente"
                  color="success"
                  v-model="userForm.user_state"
                  :rules="userstateSelectRule"
                ></v-select>
              </v-col>
            </v-row>

            <v-row align="center" justify="center">
              <v-col cols="11">
                <v-text-field
                  v-model="userForm.office"
                  label="Cargo"
                  placeholder="Cargo"
                  outlined
                  color="light-green lighten-1"
                  :rules="officeFieldRules"
                ></v-text-field>
              </v-col>
            </v-row>
            
            <v-row align="center" justify="center">
              <v-col cols="11">
                <v-text-field
                  outlined
                  color="light-green lighten-1"
                  :append-icon="showPass1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="passwordRules"
                  :type="showPass1 ? 'text' : 'password'"
                  v-model="passwords.password"
                  name="user-password"
                  placeholder="Informe a senha"
                  label="Senha"
                  @click:append="showPass1 = !showPass1"
                  data-test="passwdField"
                  autocomplete="on"
                />
              </v-col>
            </v-row>

            <v-row align="center" justify="center">
              <v-col cols="11">
                <v-text-field
                  outlined
                  color="light-green lighten-1"
                  :append-icon="showPass2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="passwordsMatch"
                  :type="showPass2 ? 'text' : 'password'"
                  v-model="passwords.password2"
                  name="user-password2"
                  label="Confirme a Senha"
                  placeholder="Informe a senha novamente"
                  autocomplete="off"
                  @click:append="showPass2 = !showPass2"
                />
              </v-col>
            </v-row>

          </v-container>
          <v-row class="mt-5" align="center" justify="center">
            <v-btn
              color="success"
              :disabled="!valid || isWorking"
              @click="validate"
              :loading="isWorking"
            >
              Criar
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import axios from 'axios';
const passwords = {
  password: "",
  password2: ""
};

export default {
  name: 'Form',
  components: {

  },
  data: () => ({
    showPass1: false,
    showPass2: false,
    userstateBoxItems: [
      { value: null, text: 'Selecione um estado' },
      { value: 'AC', text: 'Acre' },
      { value: 'AL', text: 'Alagoas' },
      { value: 'AP', text: 'Amapá' },
      { value: 'AM', text: 'Amazonas' },
      { value: 'BA', text: 'Bahia' },
      { value: 'CE', text: 'Ceará' },
      { value: 'DF', text: 'Distrito Federal' },
      { value: 'ES', text: 'Espírito Santo' },
      { value: 'GO', text: 'Goiás' },
      { value: 'MA', text: 'Maranhão' },
      { value: 'MT', text: 'Mato Grosso' },
      { value: 'MS', text: 'Mato Grosso do Sul' },
      { value: 'MG', text: 'Minas Gerais' },
      { value: 'PA', text: 'Pará' },
      { value: 'PB', text: 'Paraíba' },
      { value: 'PR', text: 'Paraná' },
      { value: 'PE', text: 'Pernambuco' },
      { value: 'PI', text: 'Piauí' },
      { value: 'RJ', text: 'Rio de Janeiro' },
      { value: 'RN', text: 'Rio Grande do Norte' },
      { value: 'RS', text: 'Rio Grande do Sul' },
      { value: 'RO', text: 'Rondônia' },
      { value: 'RR', text: 'Roraima' },
      { value: 'SC', text: 'Santa Catarina' },
      { value: 'SP', text: 'São Paulo' },
      { value: 'SE', text: 'Sergipe' },
      { value: 'TO', text: 'Tocantins' },
    ],
    passwords,
    passwordRules: [ (v) => !!v || 'Senha deve ser informada.', (v) => (v && v.length >= 6) || "Min 6 caracteres"],
    userstateSelectRule: [(v) => !!v || 'Selecione uma opção'],
    userForm: {
      name: '',
      email: '',
      agency: '',
      office: '',
      user_state: ''
    },
    newUserPost: {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      office: '',
      aggency: '',
      state: ''
    },
    isWorking: false,
    valid: true,
    recaptchaToken: '',
    nameFieldRules: [
      (v) => !!v || 'Nome é necessário',
      (v) => v?.length >= 10 || 'Nome não pode ser menor que 10 caracteres',
    ],
    officeFieldRules: [
      (v) => !!v || 'Informe o cargo',
    ],
    agencyFieldRules : [
      (v) => !!v || 'Nome completo do orgão é necessário',
    ],
    emailFieldRules: [
      (v) => !!v || 'Email é necessário',
      (v) => /.+@.+\..+/.test(v) || 'Email deve ser um email válido',
    ],
    passwordsMatch: [() => passwords.password === passwords.password2 || "Senhas não conferem"]
  }),
  methods: {
    onCaptchaVerified(recaptchaToken) {
      this.recaptchaToken = recaptchaToken;
    },
    onCaptchaExpired: function () {
      this.$refs.recaptcha.reset();
    },
    validate() {
      this.$refs.form.validate();
      this.$nextTick(this.sendData);
    },
    async sendData() {
      if (!this.valid){
        this.$swal.fire({
          icon: 'error',
          title: `Por favor, verifique os campos preenchidos.`,
        });
        return;
      }
      this.isWorking = true;

      const firstName = this.userForm.name.split(' ')[0];
      const lastName = this.userForm.name.replace(firstName,'');

      this.newUserPost = {
        first_name: firstName,
        last_name: lastName,
        email: this.userForm.email,
        password: passwords.password,
        office: this.userForm.office,
        aggency: this.userForm.agency,
        state: this.userForm.user_state,
        recaptcha: this.recaptcha
      }

      await axios
        .post(process.env.VUE_APP_SERVICE_NAME + 'api/accounts/signup/',this.newUserPost)
        .then(() => {
          this.$swal.fire({
            icon: 'success',
            title: `Usuário cadastrado com sucesso.`,
            text: `Em breve você receberá um e-email com as instruções.`
          });
          window.scrollTo(0,0);
          this.$refs.form.reset();
          this.$router.push({name: 'Loginview'});
        })
        .catch((e) => {
          this.isWorking = false;
          this.$swal.fire({
            icon: 'error',
            title: `Erro ao enviar dados`,
            text: e.response.data.detail ? e.response.data.detail : `Favor verificar os campos.`
          });
          console.log(e);
        })
        .finally(() => {
          this.isWorking = false;
          this.$refs.recaptcha.reset();
        });
    },
  }
};
</script>

<style>
.scroll-bar {
  position: absolute !important;
  top: 150px;
  left: 0;
  z-index: 150;
}
</style>
