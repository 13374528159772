<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col cols="8">
        <v-banner
          class="rounded-lg"
          justify="center"
          elevation='2'
          color="white">
         
          <v-card class="mx-auto" tile>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title><p class="font-weight-black">Email de suporte para eventuais problemas e/ou dúvidas</p></v-list-item-title>
                <v-list-item-subtitle class="ml-5 mt-1">suporte.relatorio@reclameaqui.com.br</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <a class="text-decoration-underline" @click="downloadFile">Clique aqui para download do Manual de Usuário</a>
            </v-list-item>
            
          </v-card>
        </v-banner>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  methods:{
    async downloadFile(){
        await axios.get("https://storage.googleapis.com/ra-dataanalytics-assets/manual_provisorio.pdf",{responseType: 'blob',contentType:	"application/pdf", 'Access-Control-Allow-Origin': '*'})
            .then(response => {
              var fileURL = window.URL.createObjectURL(new Blob([response.data]));
              var fileLink = document.createElement('a');
              fileLink.href = fileURL;
              fileLink.setAttribute('download', 'file.pdf');
              document.body.appendChild(fileLink);
              fileLink.click();
            }).catch(console.error)
    }
  }
};
</script>
