<template>
    <v-container fluid>
        <v-form ref="form">
            <v-row align="center" justify="center">
                <v-col cols="7">
                    <v-container align-center class="white rounded-lg">
                        <div class="mx-3">
                        <v-row v-if="report.jurid_aprov==='1' && report.data_aprov==='1'">
                            <v-avatar
                            class="mx-3"
                            color="green"
                            size="20"
                            >
                            </v-avatar>
                            Aprovado
                        </v-row>

                        <v-row v-if="report.jurid_aprov.length!==1">
                            <v-avatar
                            class="mx-3"
                            color="red"
                            size="20"
                            >
                            </v-avatar>
                            Reprovado por jurídico: "{{report.jurid_aprov}}"
                        </v-row>

                        <v-row v-if="report.data_aprov.length!==1">
                            <v-avatar
                            class="mx-3"
                            color="red"
                            size="20"
                            >
                            </v-avatar>
                            Reprovado por dados: "{{report.data_aprov}}"
                        </v-row>

                        <v-row class="mx-1 mt-3">
                            <p class="headline font-weight-light">{{report.report}}</p>
                            <v-spacer></v-spacer>
                            <p class="text-right headline font-weight-bold">
                            ID: {{ report.id }}
                            </p>
                        </v-row>

                        <v-row align="center" justify="center">
                            <v-col cols="6">
                            <v-text-field
                                label="Data Inicial"
                                outlined
                                color="light-green lighten-1"
                                v-model="report.time[0]"
                            ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                            <v-text-field
                                label="Data Final"
                                outlined
                                color="light-green lighten-1"
                                v-model="report.time[1]"
                            ></v-text-field>
                            </v-col>
                        </v-row>
                        </div>

                        <!--EMPRESA-->
                        <v-row class="mx-3 mt-3">
                        <p class="title font-weight-light">Empresa</p>
                        </v-row>
                        <v-row class="mx-1" align="center" justify="center">
                            <v-col cols="12">
                                <v-flex xs12>
                                    <v-combobox multiple
                                            v-model="report.names"
                                            label="Empresas"
                                            append-icon
                                            outlined
                                            chips
                                            color="green"
                                            deletable-chips
                                            class="tag-input">
                                    </v-combobox>
                                </v-flex>
                            </v-col>
                        </v-row>
                        <v-row class="mx-1" align="center" justify="center">
                            <v-col cols="12">
                                <v-flex xs12>
                                    <v-combobox multiple
                                            v-model="report.links"
                                            label="Links"
                                            append-icon
                                            outlined
                                            chips
                                            color="green"
                                            deletable-chips
                                            class="tag-input">
                                    </v-combobox>
                                </v-flex>
                            </v-col>
                        </v-row>
                        <!--Problema-->
                        <span ref='keywordsArray' v-for="(row2, index2) in rows2" :key="index2">
                        <v-row align="center" justify="center">
                            <v-col cols="12">
                                <v-text-field
                                    label="Qual problema gostaria de receber informações sobre?"
                                    outlined
                                    color="light-green lighten-1"
                                    v-model="row2.reportNeeds"
                                    :rules="reportNeedsRules"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="ml-3" align="left" justify="left">
                            Operadores:
                            <v-btn
                            @click="addOperator(index2, 'E')"
                            color="grey accent-1"
                            class="ml-2 rounded-lg"
                            small
                            depressed>
                            E
                            </v-btn>
                            <v-btn
                            @click="addOperator(index2, 'OU')"
                            color="grey accent-1"
                            class="ml-2 rounded-lg"
                            small
                            depressed>
                            OU
                            </v-btn>
                            <!-- <v-btn
                            @click="addOperator(index2, '!')"
                            color="grey accent-1"
                            class="ml-2 rounded-lg"
                            small
                            depressed>
                            NÃO
                            </v-btn> -->
                            <v-btn
                            @click="addOperator(index2, '(')"
                            color="grey accent-1"
                            class="ml-2 rounded-lg"
                            small
                            depressed>
                            (
                            </v-btn>
                            <v-btn
                            @click="addOperator(index2, ')')"
                            color="grey accent-1"
                            class="ml-2 rounded-lg"
                            small
                            depressed>
                            )
                            </v-btn>
                        </v-row>
                        <v-row align="center" justify="center">
                            <v-col cols="12">
                            <v-textarea
                                :ref="`keywordsField_${index2}`"
                                label="Quais as palavras-chaves que gostaria de pesquisar na base do ReclameAQUI sobre esse problema específico?"
                                outlined
                                auto-grow
                                color="light-green lighten-1"
                                v-model="row2.keyWords"
                                :rules="keyWordsRules"
                            ></v-textarea>
                            </v-col>
                        </v-row>
                        </span>
                        <v-row align="center" justify="center">
                        <v-btn
                            @click="addProblem"
                            color="green accent-1"
                            class="rounded-lg mb-5"
                            small
                            depressed
                        >
                            Adicionar outro problema
                        </v-btn>
                        <v-col cols="1"></v-col>
                        <v-btn
                            @click="removeProblem"
                            color="red accent-1"
                            class="rounded-lg mb-5"
                            small
                            depressed
                            v-if="rows2.length > 1"
                        >
                            Remover um problema
                        </v-btn>
                        </v-row>

                        <v-row>
                            <v-divider></v-divider>
                        </v-row>

                        <v-row class="mx-1" align="center" justify="center">
                            <v-col cols="12">
                                <v-select
                                v-model="report.user_state"
                                :items="userStateBoxItem"
                                :rules="userStateSelectRule"
                                small-chips
                                label="Estado(s)"
                                multiple
                                outlined
                                >
                                
                                </v-select>
                            </v-col>
                        </v-row>

                        <v-row align="center" justify="center">
                            <v-col cols="11">
                                <v-combobox
                                :rules="isRequired ? userStateCityBoxRule : []"
                                :required="isRequired"
                                ref="userStateCityBox"
                                :items="userStateCityBoxItems"
                                label="Selecione qual cidade gostaria que a busca focasse"
                                color="success"
                                v-model="report.city"
                                multiple
                                clearable
                                chips
                                ></v-combobox>
                            </v-col>
                        </v-row>

                        <v-row>
                        <v-col cols="5"></v-col>
                        <v-col cols="3">
                            <v-btn @click="updateData(report)" dark>
                            Enviar dados
                            </v-btn>
                        </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
import axios from "axios";

export default {
    data: () => ({
        rows2: [],
        report: {},
        reports: [[]],
        userAccountState:'',
        userStateCityBox: [],
        userStateCityBoxItems: [],
        userStateCityBoxRule: [(v) => !!v || 'Selecione uma opção'],
        userStateBox: [],
        userStateBoxItem: [{value:"Todos", text:"Todos"}],
        userStateSelectRule: [(v) => !!v || 'Selecione uma opção'],
        keyWordsRules: [
            (v) => !!v || 'É necessário especificar as palavras chaves',
        ],
        reportNeedsRules: [
            (v) => !!v || 'É necessário especificar o que você precisa no relatório',
        ],
    }),
    methods: {
         updateData(report) {
            const failedKeywords = this.validateKeywords()
            if(failedKeywords.length > 0){
                this.$swal.fire({
                icon: 'error',
                title: `"As palavras-chave estão com formato incorreto, por favor verifique os campos ${failedKeywords.join(", ")}"`,
                });
                return;
            }
            const reportNeeds = [];
            let keyWords = '';
            this.rows2.forEach((problem, index, array) => {
                if (index === array.length - 1){
                keyWords += problem.keyWords.replaceAll(',',' OU').replaceAll('||', ' E ');
                }else {
                keyWords += problem.keyWords.replaceAll(',',' OU').replaceAll('||', ' E ').concat(' & ');
                }
                reportNeeds.push(problem.reportNeeds.replaceAll(',',''));
            });
            report.report = reportNeeds.toString(),
            report.keywords = keyWords
            this.sendmodification(report).then(() => {
                this.$swal.fire({
                    title:'Dados Atualizados!',
                    icon: 'success',
                    confirmButtonText:'Ok!'}).then((result) => {
                        if(result.isConfirmed){
                            this.$router.push({path: '/reports', query: {modifyreport: report}})
                        }
                    })
            })

        },
        validateExpression(expression){
            try {
                eval(expression);
                return true
            }
            catch (e) {
                console.log(e)
                return false
            }
        },
        sentenceToMath(sentence){
            sentence = sentence.toLowerCase().replace(new RegExp(/([ \t]+)(ou|e)([ \t]+)/, 'g'), " + ")
            return sentence.normalize('NFD').replace(new RegExp(/SELECT|UPDATE|CREATE|DELETE|WHERE|[*,.|/\\\n]|[\u0300-\u036f]/, 'g'), "").replace(new RegExp(/OU|E|&/, 'g'), " + ").replace(new RegExp(/([ \t]*)[!]([ \t]*)/, 'g'), "-").replace(new RegExp(/([ \t]*)[a-zA-Z]([ \t]*)/, 'g'), "1")
        },
        validateKeywords(){
            var failureFinder = []
            for(var i=0;i<this.rows2.length;i++){
                const newStr = this.sentenceToMath(this.rows2[i].keyWords)
                if(this.validateExpression(newStr) != true){
                failureFinder.push(i+1)
                }
            }
            return failureFinder
        },
        addOperator(index, operador){
            this.rows2[index].keyWords += ' '+operador+' ';
            this.$refs[`keywordsField_${index}`][0].focus();
        },
        addProblem() {
        this.rows2.push({
            reportNeeds: ' ',
            keyWords: ' ',
        });
        },
        removeProblem() {
            this.rows2.pop();
        },        
        joinfields(report) {
            const keywords = report.keywords.map(item => item).join(' & ')
            report.keywords = keywords
            return report
        },
        async sendmodification(report) {
            if(report.user_state[0] == "Todos"){
                report.user_state = "Todos"
            }
            else{
                report.user_state = report.user_state.join(',')
            }
            
            const dataReport = {... report}
            dataReport.links = dataReport.links.join(',')
            dataReport.names = dataReport.names.join(',')
            dataReport.time = dataReport.time.join('~')
            dataReport.user_state = dataReport.user_state,
            dataReport.city = (dataReport.city.length >= 1 ? dataReport.city.join(',') : "Todos")

            const options = {
                method: 'PATCH',
                url: `${process.env.VUE_APP_SERVICE_NAME}api/relatorios/${dataReport.id}/`,
                headers: {
                'content-type': 'application/json',
                authorization: "Token " + localStorage.getItem("token")
                },
                data: dataReport
            };

            return await axios.request(options).catch((error) => {
                console.error(error);
                this.$swal.fire('Erro em atualizar os dados', '', 'error')
                throw error
            })
        }
    },
    watch: {
        userStateBox: function (val) {
            this.userStateCityBox = ''
            this.userStateCityBoxItems = ["Todos"]
            if (val != '' && val != 'Todos'){
                fetch(`https://storage.googleapis.com/ra-dataanalytics-assets/json/states/state_${val}.json`)
                .then(res => res.json())
                .then((out) => {
                for (let i = 0; i < out.length; i++) {
                    this.userStateCityBoxItems.push(out[i].nome)
                }})
                this.isRequired = true
            }
            else{
                this.isRequired = false
            }
        }
    },
    created() {
        fetch('https://storage.googleapis.com/ra-dataanalytics-assets/json/states.json')
        .then(res => res.json())
        .then((out) => {
            for (let i = 0; i < out.length; i++) {
                this.userStateBoxItem.push({value:out[i].sigla, text:out[i].nome})
            }})

        this.report = this.$route.query.modifyreport
        for(let x = 0; x < this.report.problems.length; x++){
            this.rows2.push({
                reportNeeds: this.report.problems[x],
                keyWords: this.report.problemsKeywords[x],
            });
        }

        fetch(`https://storage.googleapis.com/ra-dataanalytics-assets/json/states/state_${this.report.userState}.json`)
                .then(res => res.json())
                .then((out) => {
                for (let i = 0; i < out.length; i++) {
                    this.userStateCityBoxItems.push(out[i].nome)
                }})
                this.isRequired = true
    }
};
</script>
